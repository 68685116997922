import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const faqData = [
  {
    question: "授業料はいくらですか？",
    answer: "中学生の授業料は1教科13,000円、高校生は18,000円からで、複数教科の受講でお得になります。詳細は料金ページをご覧ください。"
  },
  {
    question: "体験授業は無料ですか？",
    answer: "はい、初回の体験授業は無料です。ぜひぐっとスクールの授業を体験してみてください。"
  },
  {
    question: "どの学年から入塾できますか？",
    answer: "中学1年生から高校3年生まで、全学年で入塾を受け付けています。"
  },
];

const FAQItem = ({ question, answer, index, isVisible }) => {
  const [isOpen, setIsOpen] = useState(false);

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: {
        type: "spring",
        stiffness: 100,
        damping: 12,
        delay: index * 0.1
      }
    }
  };

  return (
    <motion.div 
      className="mb-4"
      variants={itemVariants}
      initial="hidden"
      animate={isVisible ? "visible" : "hidden"}
    >
      <button
        className="flex justify-between items-center w-full text-left font-semibold py-2 px-4 bg-white rounded-lg shadow-md hover:bg-gray-50 transition-colors duration-300"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span>{question}</span>
        <span>{isOpen ? '−' : '+'}</span>
      </button>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3 }}
            className="bg-white px-4 py-2 rounded-b-lg shadow-md"
          >
            <p>{answer}</p>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

const FAQ = () => {
  const [isVisible, setIsVisible] = useState(false);
  const componentRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.1
      }
    );

    if (componentRef.current) {
      observer.observe(componentRef.current);
    }

    const currentRef = componentRef.current;

    return () => {
      if (currentRef) {
      observer.unobserve(currentRef);
      }
    };
  }, []);

  return (
    <section ref={componentRef} className="py-20">
      <div className="container mx-auto px-6">
        <motion.h2 
          className="text-3xl font-bold text-center mb-12"
          initial={{ opacity: 0, y: 20 }}
          animate={isVisible ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
          transition={{ duration: 0.5 }}
        >
          よくある質問
        </motion.h2>
        <div className="max-w-3xl mx-auto">
          {faqData.map((item, index) => (
            <FAQItem 
              key={index} 
              question={item.question} 
              answer={item.answer} 
              index={index}
              isVisible={isVisible}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQ;