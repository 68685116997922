import React from 'react';
import { motion } from 'framer-motion';
import FeatureItem from './FeatureItem';
import { FaGraduationCap, FaBrain, FaBook, FaUsers, FaCog, FaLaptop, FaSun, FaFileAlt, FaChalkboardTeacher } from 'react-icons/fa';

const features = [
  { 
    id: 1, 
    icon: FaGraduationCap, 
    title: '東大大学院合格者が直接指導', 
    description: '実力派講師があなたの学びを徹底サポート！',
    details: '東京大学大学院合格の実力派講師が、あなたの学びを直接サポート！基本を丁寧に教え、記憶の定着までしっかりと指導します。難解な問題もスムーズに解決し、安心して学べる環境で、一歩先の自分へと導きます。'
  },
  { 
    id: 2, 
    icon: FaUsers, 
    title: '少人数制で目が届く安心の学び', 
    description: 'あなたの理解度を把握し、最適なサポートを実現',
    details: '少人数制のぐっとスクールでは、個々の学習スタイルを大切にした指導が特徴です。自分のペースで学ぶことで、より深い理解と成長が期待できます。新しい学びの形を感じに、ぜひ体験してみてください！'
  },
  { 
    id: 3, 
    icon: FaCog, 
    title: 'あなたにぴったりの学びを提供！', 
    description: '共に学ぶ楽しさと、個別対応の安心感を両立！',
    details: '最新のAI技術を活用し、塾長自身が各生徒の理解度を分析します。※最近流行りの"AIに丸投げの分析"ではありません。それによって一人一人に最適なカリキュラムを提供。共に学ぶ楽しさと個別対応の安心感を両立させ、あなたの成長をサポートします。'
  },
  { 
    id: 4, 
    icon: FaChalkboardTeacher, 
    title: '集団授業で、理解を深める', 
    description: '質の高い授業で、あなたの目標を実現',
    details: '最新のICT機器を活用し、視覚的にわかりやすい授業を展開します。問題演習を通じて、暗記や理解を深める工夫も。体験授業でその効果を実感してみませんか？'
  },
  { 
    id: 5, 
    icon: FaBook, 
    title: '誰でもできる効率的暗記法', 
    description: '暗記力の差なんてほとんどない！',
    details: '誰でも効率的な暗記法を学ぶことで、驚くほどの記憶力を手に入れることができます。当塾では、伝統のある記憶術を駆使して、わかりやすく楽しく学べる環境を提供。あなたも新たな自分を見つけましょう！'
  },
  { 
    id: 6, 
    icon: FaBrain, 
    title: '本質を知り、応用力をつける', 
    description: '暗記を超えて、真の理解へ。応用力はここにある！',
    details: 'ぐっとスクールでは、単なる暗記だけではなく、学問の本質をじっくりと掘り下げることを大切にしています。なぜその公式が成り立つのか、どうしてその法則が適用できるのかを丁寧に解説します。これにより、あなたの理解は深まり、さまざまな問題に対する応用力が自然と身についていきます。'
  },
  { 
    id: 7, 
    icon: FaFileAlt, 
    title: '充実したオリジナル教材', 
    description: 'ぐっとスクール独自の教材で効果的に学習。',
    details: '長年の指導経験と最新の教育研究に基づいて開発された、ぐっとスクール独自の教材を使用します。基礎から応用まで段階的に学べる構成で、各単元の要点を効率的に押さえつつ、深い理解を促します。定期的に更新され、常に最新の入試傾向に対応しています。'
  },
  { 
    id: 8, 
    icon: FaLaptop, 
    title: '3,000問を超えるオンライン問題', 
    description: '24時間アクセス可能な自動採点システムを使い放題',
    details: '「ぐっとスクール」では、3,000問を超えるオリジナル問題に24時間いつでもオンラインでアクセス可能！自動採点システムが、あなたの学習をリアルタイムでサポートし、苦手を克服する力強い味方になります。自宅での学びを充実させ、塾での授業と連携させて、確実な成績アップを目指しましょう！'
  },
  { 
    id: 9, 
    icon: FaSun, 
    title: '朝6時半から！自習室オープン', 
    description: '質の高い学習は朝から。自習室で集中力を高めよう',
    details: '朝6時半からの自習室オープンで、学習習慣を身につける絶好の機会がここに！集中力が高まるこの時間帯を活かして、あなたの学びを一段とレベルアップさせましょう。'
  },
];

const FeatureList = () => {
  return (
    <div id="特徴" className="bg-gray-100 py-16">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-12 text-gray-800">ぐっとスクール<span style={{ color: 'red' }}>9</span>のこだわり</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {features.map((feature, index) => (
            <motion.div
              key={feature.id}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <FeatureItem {...feature} />
            </motion.div>
          ))}
        </div>
        <p className="text-xl font-bold text-center mt-12 text-gray-800">いいものを追求し続けた結果、特徴が増えてしまいました。</p>
        <p className="text-xl font-bold text-center mt-4 text-gray-800">"塾長の気合"と受け取ってください。</p>
      </div>
    </div>
  );
};

export default FeatureList;