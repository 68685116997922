import React, { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';

const ContactLinkPreview = () => {
  const [isVisible, setIsVisible] = useState(false);
  const componentRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.1
      }
    );

    if (componentRef.current) {
      observer.observe(componentRef.current);
    }

    const currentRef = componentRef.current;

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  return (
    <div id="お問い合わせ" ref={componentRef} className="flex justify-center items-center w-full py-12 bg-gradient-to-b from-white to-blue-50">
      <motion.div 
        className="block w-full max-w-[400px] bg-white border-2 border-blue-300 rounded-2xl p-6 mx-4 shadow-lg"
        initial={{ opacity: 0, y: 20 }}
        animate={isVisible ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
        transition={{ duration: 0.5 }}
      >
        <motion.div 
          className="text-sm font-bold text-center text-blue-600 mb-4"
          initial={{ opacity: 0 }}
          animate={isVisible ? { opacity: 1 } : { opacity: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          お気軽にお試し・お問い合わせください。
        </motion.div>
        <motion.a
          href="https://comiru.jp/guttoschool/customer/application/form" 
          target="_blank" 
          rel="noopener noreferrer"
          className="block p-4 bg-gradient-to-r from-orange-500 to-orange-400 rounded-xl text-white text-xl font-bold text-center leading-7 shadow-md hover:shadow-lg transition-all duration-300 transform hover:-translate-y-1"
          initial={{ scale: 0.9 }}
          animate={isVisible ? { scale: 1 } : { scale: 0.9 }}
          transition={{ duration: 0.5, delay: 0.3 }}
          whileHover={{ scale: 1.05, backgroundColor: '#ff6a00' }}
          whileTap={{ scale: 0.95 }}
        >
          無料体験・お問い合わせ
          <br />こちらから！
        </motion.a>
        <div className="relative w-full mt-6">
          <motion.div 
            className="text-sm font-bold text-[#493B32] text-center mt-4"
            initial={{ opacity: 0, y: 20 }}
            animate={isVisible ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            ちょっとしたご質問や<br />ご相談だけでもお気軽に！
          </motion.div>
        </div>
        <div className="h-0 border-t border-gray-300 my-4"></div>
        <motion.div 
          className="mt-2 text-sm text-center text-[#493B32]"
          initial={{ opacity: 0 }}
          animate={isVisible ? { opacity: 1 } : { opacity: 0 }}
          transition={{ duration: 0.5, delay: 0.7 }}
        >
          受付時間 13:00~21:00<span className="ml-1">（土日祝含む）</span>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default ContactLinkPreview;