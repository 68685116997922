import React from 'react';

const AccessInfo = () => {
const mapUrl = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6538.876729608999!2d137.14847217459885!3d34.97068296868087!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6004a2bea96eb5bd%3A0xe25886207b96fca6!2z44GQ44Gj44Go44K544Kv44O844Or!5e0!3m2!1sja!2sjp!4v1726436958525!5m2!1sja!2sjp";
const googleMapsUrl = "https://maps.app.goo.gl/g9rxGmjyhq2p3tza6";

  return (
    <section id="アクセス" className="py-16 bg-gray-100">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-8">アクセス</h2>
        <div className="flex flex-col md:flex-row gap-8">
          <div className="w-full md:w-1/2">
            <iframe
              src={mapUrl}
              width="100%"
              height="350"
              style={{border:0}}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="ぐっとスクール位置"
              className="rounded-lg shadow-md"
            ></iframe>
          </div>
          <div className="w-full md:w-1/2">
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold mb-4">ぐっとスクール</h3>
              <p className="mb-2"><strong>住所：</strong> 〒444-0917 岡崎市日名西町8-8</p>
              <p className="mb-2"><strong>電話：</strong> 090-4183-9663</p>
              <p className="mb-4"><strong>最寄り駅：</strong> 愛知環状鉄道　北岡崎駅(徒歩10分)</p>
              <a 
                href={googleMapsUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="inline-block bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-300"
              >
                Google マップで見る
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AccessInfo;