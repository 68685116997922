import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X, ChevronRight } from 'lucide-react';

const courseData = {
  中学生: [
    { 
      subject: "数学", 
      description: "計算力と本質理解の両輪で、数学力を飛躍的に向上！", 
      details: (
        <section className="course-description">
          <h3>数学コース</h3>
          <p>計算力と本質理解を同時に養成し、数学力を飛躍的に向上させます。</p>
          <h4>私たちの強み:</h4>
          <ul>
            <li><strong>計算力の向上:</strong> 正確さとスピードを磨きます。</li>
            <li><strong>本質理解の追求:</strong> 「なぜ」を探求し、深い理解を促します。</li>
            <li><strong>実戦力の養成:</strong> テスト本番で実力を発揮できるよう指導します。</li>
          </ul>
          <p>百聞は一見に如かず。ぜひ一度体験にお越しください！</p>

        </section>
      ), 
      bgImage: "images/math.png" 
    },
    { 
      subject: "理科",
      description: "効率の良い暗記と深い理解の両輪\n「なるほど！」を大切にする学び",
      details: (
        <section className="course-description">
        <h3>理科コース</h3>
        <p>科学の魅力と奥深さを一緒に探求しましょう。</p>
        <h4>私たちの特徴</h4>
        <ul>
          <li><strong>効率的な知識習得:</strong> 独自の暗記法で短期間で必要な知識を身につけます。</li>
          <li><strong>本質的な理解:</strong> 背景や全体像を把握し、深い理解を促進します。</li>
          <li><strong>個別最適化:</strong> 一人ひとりの理解度に合わせた指導を行います。</li>
        </ul>
        <p>「わかる」と「できる」を体感。ぜひ一度、体験授業で実感してください！</p>
      </section>
      ),
      bgImage: "images/Science.png" 
    },
    {
      subject: "英語", 
      description: "効率的な習得と深い理解の融合 「わかる！」と「できる！」を実感", 
      details: (
        <section className="course-description">
          <h3>英語コース</h3>
          <p>言語の魅力と可能性を体感しましょう！</p>
          <h4>私たちの特徴</h4>
          <ul>
            <li><strong>効率的な暗記:</strong> 短期間で効果的に暗記する学習法を提供します。</li>
            <li><strong>本質的な言語理解:</strong> 英語のしくみを深く理解し、真の習得を促進します。</li>
            <li><strong>個別最適化:</strong> 習熟度と目標に応じた学習プランで、一人ひとりに合わせた指導を行います。</li>
          </ul>
          <p>英語学習をより魅力的なものに。ぜひ一度、体験授業で実感してください！</p>
        </section>
      ),
      bgImage: "images/english.png" 
    },
    { 
      subject: "社会", 
      description: "理解と暗記の最強コンビ！未来を築く学びの場",
      details: (
        <section className="course-description">
          <h3>社会科コース</h3>
          <p>知識の習得と深い洞察の融合 「なるほど！」と「使える！」を実感する学びの場です。</p>
          <h4>授業の特徴</h4>
          <ul>
            <li><strong>効率的な知識習得:</strong> 独自の暗記術で必要な情報を確実に身につけます。</li>
            <li><strong>歴史:</strong> 時代のストーリーを通じて背景を理解し、出来事の繋がりを把握します。</li>
            <li><strong>地理:</strong> 「なぜ？」という視点で地形の特徴を探求し、地理的思考力を育成します。</li>
            <li><strong>公民:</strong> 社会に役立つ教養を身につけながら、効率的な暗記術を活用します。</li>
            <li><strong>総合的アプローチ:</strong> 理解と暗記の両立で、深い学びと確実な知識定着を実現します。</li>
          </ul>
          <p>社会科の学びをより魅力的で実践的なものに。ぜひ一度、体験授業で実感してください！</p>
        </section>
      ),
      bgImage: "images/history.png"
    }
  ],
  高校生: [
    { 
      subject: "数学", 
      description: "計算力と本質理解の両輪で、数学力を飛躍的に向上！", 
      details: (
        <section className="course-description">
          <h3>数学コース（高校生）</h3>
          <p>大学入試を見据えた高度な問題解決力を養成します。</p>
          <h4>私たちの特徴</h4>
          <ul>
            <li><strong>深い理解:</strong> 数学的概念の本質を理解し、応用力を養います。</li>
            <li><strong>問題解決力:</strong> 複雑な問題に対する戦略的アプローチを学びます。</li>
            <li><strong>入試対策:</strong> 過去問分析と傾向対策で、本番で力を発揮できるよう指導します。</li>
          </ul>
          <h4>授業の特徴</h4>
          <ul>
            <li><strong>個別最適化:</strong> 生徒一人ひとりの理解度と目標に合わせた指導を行います。</li>
            <li><strong>高度な演習:</strong> 大学入試レベルの問題を用いた演習で実践力を養成します。</li>
            <li><strong>フィードバック:</strong> 詳細な解説と個別フィードバックで、弱点を克服します。</li>
          </ul>
          <p>数学の真の力を身につけ、大学入試で成功を掴みましょう。体験授業でその違いを実感してください！</p>
        </section>
      ),
      bgImage: "images/math.png" 
    },
    { 
      subject: "物理基礎・物理",
      description: "物理・化学・生物の専門的な指導",
      details: (
        <section className="course-description">
          <h3>物理コース（高校生）</h3>
          <p>物理の基礎から応用まで、深い理解と実践力を養成します。</p>
          <h4>私たちの特徴</h4>
          <ul>
            <li><strong>概念理解:</strong> 物理法則の本質を理解し、現象の背景を探求します。</li>
            <li><strong>問題解決力:</strong> 複雑な問題を分析し、効果的に解決する力を養います。</li>
            <li><strong>実験・観察:</strong> 理論と実践を結びつけ、物理現象への洞察力を深めます。</li>
          </ul>
          <h4>授業の特徴</h4>
          <ul>
            <li><strong>体系的学習:</strong> 基礎から応用まで、段階的に理解を深めていきます。</li>
            <li><strong>計算力強化:</strong> 物理特有の計算スキルを徹底的に強化します。</li>
            <li><strong>入試対策:</strong> 過去問分析と演習を通じて、入試本番での高得点を目指します。</li>
          </ul>
          <p>物理の魅力を発見し、科学的思考力を磨きましょう。体験授業で、その違いを実感してください！</p>
        </section>
      ),
      bgImage: "images/physics.png" 
    },
    { 
      subject: "化学基礎・化学",
      description: "化学の総合的な学習と実践",
      details: (
        <section className="course-description">
          <h3>化学コース（高校生）</h3>
          <p>化学の基礎から応用まで、体系的に学び、実践力を養成します。</p>
          <h4>私たちの特徴</h4>
          <ul>
            <li><strong>原理理解:</strong> 化学反応の原理や法則を深く理解し、応用力を養います。</li>
            <li><strong>実験スキル:</strong> 安全で効果的な実験技術を習得し、観察力を磨きます。</li>
            <li><strong>問題解決力:</strong> 複雑な化学問題を分析し、効率的に解決する力を養います。</li>
          </ul>
          <h4>授業の特徴</h4>
          <ul>
            <li><strong>体系的学習:</strong> 無機化学、有機化学、物理化学を総合的に学習します。</li>
            <li><strong>実践的演習:</strong> 理論を実際の問題に適用する演習を重視します。</li>
            <li><strong>入試対策:</strong> 大学入試問題の傾向分析と対策で、高得点獲得を目指します。</li>
          </ul>
          <p>化学の奥深さを探求し、科学的思考力を磨きましょう。体験授業で、その違いを実感してください！</p>
        </section>
      ),
      bgImage: "images/chemistry.png" 
    },
    {
      subject: "英語", 
      description: "読解力と作文力を重視した受験対策", 
      details: (
        <section className="course-description">
          <h3>英語コース（高校生）</h3>
          <p>大学入試に向けた高度な英語力を養成します。</p>
          <h4>私たちの特徴</h4>
          <ul>
            <li><strong>読解力強化:</strong> 複雑な文章を正確に理解し、深く分析する力を養います。</li>
            <li><strong>作文力向上:</strong> 論理的で説得力のある英文を書く能力を磨きます。</li>
            <li><strong>語彙・文法:</strong> 高度な語彙と文法を効率的に習得し、実践で活用できるよう指導します。</li>
          </ul>
          <h4>授業の特徴</h4>
          <ul>
            <li><strong>多読プログラム:</strong> 様々なジャンルの英文に触れ、読解スピードと理解力を向上させます。</li>
            <li><strong>ライティング演習:</strong> 定期的な作文課題と添削指導で、英作文力を強化します。</li>
            <li><strong>リスニング強化:</strong> ネイティブスピーカーの音声教材を使用し、実践的なリスニング力を養成します。</li>
            <li><strong>入試対策:</strong> 過去問分析と演習を通じて、入試本番での高得点を目指します。</li>
          </ul>
          <p>英語力を総合的に伸ばし、グローバルな視野を育てましょう。体験授業で、その違いを実感してください！</p>
        </section>
      ),
      bgImage: "images/english.png" 
    }
  ]
};

const Courses = () => {
  const [activeTab, setActiveTab] = useState("中学生");
  const [isVisible, setIsVisible] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const componentRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.1
      }
    );

    if (componentRef.current) {
      observer.observe(componentRef.current);
    }

    const currentRef = componentRef.current;

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { 
      opacity: 1,
      transition: { 
        staggerChildren: 0.1,
        delayChildren: 0.2,
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: {
        type: "spring",
        stiffness: 100,
        damping: 12
      }
    }
  };

  const handleCourseClick = (course) => {
    setSelectedCourse(course);
  };

  const closeModal = () => {
    setSelectedCourse(null);
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      closeModal();
    }
  };

  return (
    <section id="コース" ref={componentRef} className="py-20 bg-gray-100">
      <div className="container mx-auto px-6">
        <motion.h2 
          initial={{ opacity: 0, y: 20 }}
          animate={isVisible ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
          transition={{ duration: 0.5 }}
          className="text-4xl font-bold text-center mb-12"
        >
          コース紹介
        </motion.h2>
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={isVisible ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          className="flex justify-center mb-8"
        >
          {Object.keys(courseData).map((course) => (
            <button
              key={course}
              onClick={() => setActiveTab(course)}
              className={`px-6 py-2 mx-2 rounded-full transition duration-300 ${
                activeTab === course
                  ? "bg-blue-600 text-white"
                  : "bg-white text-gray-800 hover:bg-blue-100"
              }`}
            >
              {course}
            </button>
          ))}
        </motion.div>
        <AnimatePresence mode="wait">
          <motion.div
            key={activeTab}
            variants={containerVariants}
            initial="hidden"
            animate={isVisible ? "visible" : "hidden"}
            exit="hidden"
            className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8"
          >
            {courseData[activeTab].map((course, index) => (
              <motion.div
                key={index}
                variants={itemVariants}
                whileHover={{ scale: 1.05, transition: { duration: 0.2 } }}
                className="bg-white rounded-lg shadow-lg overflow-hidden cursor-pointer"
                onClick={() => handleCourseClick(course)}
              >
                <div 
                  className="h-48 bg-cover bg-center relative"
                  style={{ backgroundImage: `url(${course.bgImage})` }}
                >
                  
                </div>
                <div className="p-6">
                  <h3 className="text-xl font-semibold mb-2">{course.subject}</h3>
                  <p className="text-gray-600 mb-4">{course.description}</p>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleCourseClick(course);
                    }}
                    className="text-blue-600 hover:text-blue-800 transition duration-300 flex items-center"
                  >
                    詳細を見る
                    <ChevronRight size={16} className="ml-1" />
                  </button>
                </div>
              </motion.div>
            ))}
          </motion.div>
        </AnimatePresence>
      </div>

      <AnimatePresence>
        {selectedCourse && (
          <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4"
            onClick={handleOverlayClick}
          >
            <motion.div 
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.9, opacity: 0 }}
              transition={{ duration: 0.3 }}
              className="bg-white rounded-lg shadow-xl max-w-2xl w-full max-h-[90vh] overflow-y-auto"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="p-6 sm:p-8">
                <div className="flex justify-between items-center mb-6">
                  <h2 className="text-3xl font-bold text-gray-900">{selectedCourse.subject}</h2>
                  <button
                    onClick={closeModal}
                    className="text-gray-400 hover:text-gray-600 transition duration-300"
                  >
                    <X size={24} />
                  </button>
                </div>
                <div className="course-details space-y-6">
                  {React.Children.map(selectedCourse.details.props.children, (child, index) => {
                    if (child.type === 'h3') {
                      return React.cloneElement(child, {
                        className: 'text-2xl font-semibold text-blue-600 mb-3'
                      });
                    } else if (child.type === 'h4') {
                      return React.cloneElement(child, {
                        className: 'text-xl font-semibold text-gray-800 mt-6 mb-3'
                      });
                    } else if (child.type === 'p') {
                      return React.cloneElement(child, {
                        className: 'text-gray-600 leading-relaxed'
                      });
                    } else if (child.type === 'ul') {
                      return (
                        <div className="bg-gray-50 rounded-lg p-4 shadow-inner">
                          {React.cloneElement(child, {
                            className: 'list-disc list-inside space-y-2',
                            children: React.Children.map(child.props.children, (li) => 
                              React.cloneElement(li, {
                                className: 'text-gray-700'
                              })
                            )
                          })}
                        </div>
                      );
                    }
                    return child;
                  })}
                </div>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </section>
  );
};

export default Courses;