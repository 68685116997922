import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaCheck } from 'react-icons/fa';

const feeData = {
  中学生: [
    { courses: '1教科', price: 13000, savings: 0, features: ['基本的な学習サポート', '期末・学年末テスト終了ごとに学習相談', 'オンライン教材の無料提供'] },
    { courses: '2教科', price: 17000, savings: 9000, features: ['2教科の総合的な学習', '定期テスト終了ごとに学習相談', 'オンライン教材の無料提供'] },
    { courses: '3教科', price: 25000, savings: 14000, features: ['3教科の総合的な学習', '定期テスト終了ごとに学習相談', 'オンライン教材の無料提供', '個別学習計画の作成'] },
    { courses: '4教科', price: 30000, savings: 22000, features: ['全教科の総合的な学習', '月1回の学習相談', 'オンライン教材の無料提供', '個別学習計画の作成'] },
  ],
  高校生: [
    { courses: '1教科', price: 18000, savings: 0, features: ['受験対策重視の指導'] },
    { courses: '2教科', price: 28000, savings: 8000, features: ['2教科の総合的な学習'] },
    { courses: '3教科', price: 35000, savings: 19000, features: ['3教科の総合的な学習'] },
    { courses: '4教科', price: 40000, savings: 32000, features: ['全教科の総合的な学習'] },
  ],
  個別指導と家庭教師: [
    { courses: '1対1　週1回', price: 58000, savings: 0, features: ['応相談','まずは集団授業からのご検討を','集団クラスでも個別サポートできます'] },
  ]
  // 中学生: [
  //   { courses: '1教科', price: 13000, savings: 0, features: ['基本的な学習サポート', '月1回の学習相談'] },
  //   { courses: '2教科', price: 17000, savings: 9000, features: ['2教科の総合的な学習', '月2回の学習相談', '教科間の連携学習'] },
  //   { courses: '3教科', price: 25000, savings: 14000, features: ['3教科の総合的な学習', '週1回の学習相談', '個別学習計画の作成'] },
  //   { courses: '4教科', price: 30000, savings: 22000, features: ['全教科の総合的な学習', '週2回の学習相談', '進路指導サポート', 'オンライン教材の無料提供'] },
  // ],
  // 高校生: [
  //   { courses: '1教科', price: 18000, savings: 0, features: ['受験対策重視の指導', '月1回の学習相談'] },
  //   { courses: '2教科', price: 28000, savings: 8000, features: ['2教科の総合的な学習', '月2回の学習相談', '模試対策サポート'] },
  //   { courses: '3教科', price: 35000, savings: 19000, features: ['3教科の総合的な学習', '週1回の学習相談', '個別学習計画の作成'] },
  //   { courses: '4教科', price: 40000, savings: 32000, features: ['全教科の総合的な学習', '週2回の学習相談', '大学入試対策', 'オンライン教材の無料提供'] },
  // ]
};

const FeeCard = ({ courses, price, savings, features, isPopular, index, isVisible }) => {
  const cardVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: {
        type: "spring",
        stiffness: 100,
        damping: 12,
        delay: index * 0.1
      }
    }
  };

  return (
    <motion.div
      className={`bg-white rounded-lg shadow-lg p-6 flex flex-col ${isPopular ? 'border-2 border-blue-500' : ''}`}
      variants={cardVariants}
      initial="hidden"
      animate={isVisible ? "visible" : "hidden"}
      whileHover={{ scale: 1.05 }}
      transition={{ type: 'spring', stiffness: 300 }}
    >
      {isPopular && (
        <div className="bg-blue-500 text-white py-1 px-4 rounded-full self-start mb-4">
          人気プラン
        </div>
      )}
      <h3 className="text-2xl font-bold mb-4">{courses}</h3>
      <p className="text-4xl font-bold mb-2">{price.toLocaleString()}円<span className="text-base font-normal">/月</span></p>
      {savings > 0 && (
        <p className="text-green-600 font-semibold mb-4">{savings.toLocaleString()}円お得！</p>
      )}
      <ul className="mb-6 flex-grow">
        {features.map((feature, index) => (
          <li key={index} className="flex items-center mb-2">
            <FaCheck className="text-green-500 mr-2" />
            {feature}
          </li>
        ))}
      </ul>
      {/* <button className="bg-blue-500 text-white py-2 px-4 rounded-full hover:bg-blue-600 transition duration-300">
        申し込む
      </button> */}
    </motion.div>
  );
};

const MonthlyFee = () => {
  const [activeTab, setActiveTab] = useState("中学生");
  const [isVisible, setIsVisible] = useState(false);
  const componentRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.1
      }
    );
    if (componentRef.current) {
      observer.observe(componentRef.current);
    }

    const currentRef = componentRef.current;

    return () => {
      if (currentRef) {
      observer.unobserve(currentRef);
      }
    };
    }, []);

  return (
    <section id="料金" ref={componentRef} className="py-20 bg-gray-100">
      <div className="container mx-auto px-6">
        <motion.h2 
          className="text-4xl font-bold text-center mb-12"
          initial={{ opacity: 0, y: 20 }}
          animate={isVisible ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
          transition={{ duration: 0.5 }}
        >
          月謝プラン
        </motion.h2>
        <motion.div 
          className="flex justify-center mb-8"
          initial={{ opacity: 0, y: 20 }}
          animate={isVisible ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          {Object.keys(feeData).map((course) => (
            <button
              key={course}
              onClick={() => setActiveTab(course)}
              className={`px-6 py-2 mx-2 rounded-full transition duration-300 ${
                activeTab === course
                  ? "bg-blue-600 text-white"
                  : "bg-white text-gray-800 hover:bg-blue-100"
              }`}
            >
              {course}
            </button>
          ))}
        </motion.div>
        <AnimatePresence mode="wait">
          <motion.div
            key={activeTab}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
            className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8"
          >
            {feeData[activeTab].map((fee, index) => (
              <FeeCard
                key={index}
                {...fee}
                isPopular={index === 1}
                index={index}
                isVisible={isVisible}
              />
            ))}
          </motion.div>
        </AnimatePresence>
        <motion.p 
          className="text-center mt-8 text-gray-600"
          initial={{ opacity: 0, y: 20 }}
          animate={isVisible ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
          transition={{ duration: 0.5, delay: 0.4 }}
        >
          ※ すべてのプランに入会金10,000円（教材費込み）が必要です。<br />
          教科の組み合わせは自由です。お気軽にご相談ください。<br />
          ※中学3年生は受験対策プランもあるため月謝が異なります。詳しくはお問い合わせください。
        </motion.p>
      </div>
    </section>
  );
};

export default MonthlyFee;