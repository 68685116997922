import React from 'react';
import { Facebook, Twitter, Instagram, Mail, Phone, MapPin } from 'lucide-react';

const Footer = () => {
  const googleMapsUrl = "https://maps.app.goo.gl/g9rxGmjyhq2p3tza6";

  return (
    <footer className="bg-blue-600 text-white">
      <div className="container mx-auto px-4 py-12">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {/* 塾の基本情報 */}
          <div>
            <h3 className="text-2xl font-bold mb-4">ぐっとスクール</h3>
            <p className="mb-2 flex items-center">
              <MapPin size={18} className="mr-2 flex-shrink-0" />
              <a 
                href={googleMapsUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="hover:underline"
              >
                〒444-0917 愛知県岡崎市日名西町8-8
              </a>
            </p>
            <p className="mb-2 flex items-center">
              <Phone size={18} className="mr-2 flex-shrink-0" />
              <a href="tel:090-4183-9663" className="hover:underline">090-4183-9663</a>
            </p>
            <p className="flex items-center">
              <Mail size={18} className="mr-2 flex-shrink-0" />
              <a href="mailto:guttoschool.okazaki@gmail.com" className="hover:underline">
                guttoschool.okazaki@gmail.com
              </a>
            </p>
          </div>

          {/* クイックリンク */}
          <div>
            <h4 className="text-xl font-semibold mb-4">お問い合わせ</h4>
            <a 
              href="https://line.me/R/ti/p/@740wrjmi" 
              target="_blank" 
              rel="noopener noreferrer" 
              className="flex items-center hover:underline"
            >
              <img src="images/line-icon.png" alt="LINEでお問い合わせ" className="h-6 w-6 mr-2" />
              LINEでお問い合わせ
            </a>
          </div>

          {/* その他の情報 */}
          {/* <div>
            <h4 className="text-xl font-semibold mb-4">その他の情報</h4>
            <ul className="space-y-2">
              {['プライバシーポリシー', '利用規約', 'よくある質問', 'ブログ'].map((item) => (
                <li key={item}>
                  <a href="#" className="hover:underline">{item}</a>
                </li>
              ))}
            </ul>
          </div> */}

          {/* SNSリンクとニュースレター登録 */}
          {/* <div>
            <h4 className="text-xl font-semibold mb-4">フォローする</h4>
            <div className="flex space-x-4 mb-4">
              {[Facebook, Twitter, Instagram].map((Icon, index) => (
                <a key={index} href="#" className="hover:text-blue-300 transition duration-300">
                  <Icon size={24} />
                </a>
              ))}
            </div>
            <h4 className="text-xl font-semibold mb-2">ニュースレター登録</h4>
            <form className="flex">
              <input
                type="email"
                placeholder="メールアドレス"
                className="flex-grow p-2 rounded-l-md text-gray-800 focus:outline-none"
              />
              <button
                type="submit"
                className="bg-yellow-500 text-blue-800 px-4 py-2 rounded-r-md hover:bg-yellow-400 transition duration-300"
              >
                登録
              </button>
            </form>
          </div> */}
        </div>

        {/* 著作権表示 */}
        <div className="mt-8 pt-8 border-t border-blue-500 text-center">
          <p>&copy; {new Date().getFullYear()} ぐっとスクール. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;