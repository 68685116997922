import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import Header from './components/Header';
import Footer from './components/Footer';
import Hero from './components/Hero';
import Features from './components/Features';
import Courses from './components/Courses';
import Testimonials from './components/Testimonials';
import TeacherProfile from './components/TeacherProfile';
import FAQ from './components/FAQ';
import BlogSection from './components/BlogSection';
import CTABanner from './components/CTABanner';
import FeatureList from './components/Features/FeatureList';
import ScheduleAndFees from './components/ScheduleAndFees/ScheduleAndFees';
import MonthlyFee from './components/MonthlyFee';
import Contact from './components/Contact';
import ContactLinkPreview from './components/ContactLinkPreview';
import AccessInfo from './components/AccessInfo';


const App = () => {
  return (
    <div className="font-sans bg-gray-100">
      <Header />
      <Hero />
      <FeatureList />
      <Courses />
      <TeacherProfile />
      <ScheduleAndFees />
      <FAQ />
      <MonthlyFee />
      <ContactLinkPreview />
      <AccessInfo />
      <Footer />
    </div>
  );
};

export default App;