import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showHeader, setShowHeader] = useState(false);
  const headerRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY > 10;
      setIsScrolled(scrolled);
      if (scrolled) {
        setShowHeader(true);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // 時間差でヘッダーを表示
    const timer = setTimeout(() => {
      setShowHeader(true);
    }, 11000);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      clearTimeout(timer);
    };
  }, []);

  const navItems = ['特徴', 'コース', '講師紹介', 'アクセス', 'お問い合わせ'];

  const scrollToContact = (e) => {
    e.preventDefault();
    const contactSection = document.getElementById('お問い合わせ');
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: 'smooth' });
    }
    if (isMenuOpen) {
      setIsMenuOpen(false);
    }
  };

  return (
    <AnimatePresence>
      {showHeader && (
        <motion.header
          ref={headerRef}
          initial={{ opacity: 0, y: -100 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -100 }}
          transition={{ duration: 0.5 }}
          className={`fixed w-full z-50 transition-all duration-300 ${
            isScrolled ? 'bg-white shadow-lg' : 'bg-transparent'
          }`}
        >
          <div className="container mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between items-center">
              <motion.a 
                href="#top"
                className="flex items-center h-16 cursor-pointer"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <img src="/images/logo.png" alt="ぐっとスクールロゴ" className="h-full w-auto object-contain" />
              </motion.a>

              <nav className="hidden md:flex space-x-8 h-16 items-center"> 
                {navItems.map((item) => (
                  <motion.a
                    key={item}
                    href={`#${item}`}
                    className="text-primary hover:text-secondary transition-colors duration-300"
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    {item}
                  </motion.a>
                ))}
              </nav>

              <div className="flex items-center space-x-4">
                <motion.a
                  href="#お問い合わせ"
                  className="bg-blue-600 text-white px-4 sm:px-6 py-2 sm:py-3 rounded-full shadow-lg hover:bg-blue-700 transition duration-300 text-sm sm:text-base"
                  whileHover={{ scale: 1.05 }}
                >
                  <span>無料体験</span>
                  <span className="hidden sm:inline">を申し込む</span>
                </motion.a>

                <motion.button
                  className="md:hidden text-primary focus:outline-none h-16"
                  onClick={() => setIsMenuOpen(!isMenuOpen)}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                >
                  <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                  </svg>
                </motion.button>
              </div>
            </div>
          </div>
          <AnimatePresence>
            {isMenuOpen && (
              <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: 'auto' }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ duration: 0.3 }}
                className="md:hidden bg-white shadow-lg"
              >
                <nav className="flex flex-col p-4">
                  {navItems.map((item) => (
                    <motion.a
                      key={item}
                      href={`#${item}`}
                      className="py-2 text-primary hover:text-secondary transition-colors duration-300"
                      onClick={() => setIsMenuOpen(false)}
                      whileHover={{ x: 5 }}
                    >
                      {item}
                    </motion.a>
                  ))}
                </nav>
              </motion.div>
            )}
          </AnimatePresence>
        </motion.header>
      )}
    </AnimatePresence>
  );
};

export default Header;