import React, { useState, useMemo, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';

const schedule = [
  {
    day: '月曜日',
    classes: [{ time: '終日', subject: '家庭教師', grade: '学年' }]
  },
  {
    day: '火曜日',
    classes: [
      { time: '18:30 - 19:20', subject: '文系数学', grade: '高3' },
      { time: '19:30 - 20:20', subject: '社会', grade: '中3' },
      { time: '20:25 - 21:00', subject: '英語', grade: '中3' },
      { time: '21:00 - 21:45', subject: '高校入試対策英国', grade: '中3' }
    ]
  },
  {
    day: '水曜日',
    classes: [
      { time: '18:20 - 19:10', subject: '社会・理科', grade: '中3' },
      { time: '19:20 - 20:10', subject: '数学', grade: '中3' },
      { time: '20:15 - 20:50', subject: '高校入試対策数理', grade: '中3' }
    ]
  },
  {
    day: '木曜日',
    classes: [
      { time: '16:30 - 17:20', subject: '英語', grade: '中1' },
      { time: '19:00 - 19:50', subject: '理科', grade: '中2' },
      { time: '20:00 - 20:50', subject: '数学', grade: '中2' }
    ]
  },
  {
    day: '金曜日',
    classes: [{ time: '終日', subject: '休講', grade: '学年' }]
  },
  {
    day: '土曜日',
    classes: [
      { time: '17:00 - 17:50', subject: '理科', grade: '中1' },
      { time: '18:00 - 18:50', subject: '数学', grade: '中1' }
    ]
  },
  {
    day: '日曜日',
    classes: [
      { time: '13:30 - 14:20', subject: '英語', grade: '中2' },
      { time: '14:30 - 15:20', subject: '社会', grade: '中2' },
      { time: '16:10 - 17:20', subject: '英語', grade: '高3' },
      { time: '17:30 - 20:00', subject: '個別指導', grade: '学年' }
    ]
  }
];

const grades = ['学年', '中1', '中2', '中3', '高3'];
const subjects = ['教科', '数学', '英語', '理科', '社会', '個別指導', '高校入試対策'];

const ScheduleTable = () => {
  const [selectedGrade, setSelectedGrade] = useState('学年');
  const [selectedSubject, setSelectedSubject] = useState('教科');
  const [isVisible, setIsVisible] = useState(false);
  const componentRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.1
      }
    );

    if (componentRef.current) {
      observer.observe(componentRef.current);
    }

    return () => {
      if (componentRef.current) {
        observer.unobserve(componentRef.current);
      }
    };
  }, []);

  const filteredSchedule = useMemo(() => {
    return schedule.map(day => ({
      ...day,
      classes: day.classes.filter(cls => 
        (selectedGrade === '学年' || cls.grade === selectedGrade) &&
        (selectedSubject === '教科' || cls.subject.includes(selectedSubject))
      )
    })).filter(day => day.classes.length > 0);
  }, [selectedGrade, selectedSubject]);

  return (
    <div ref={componentRef} className="bg-gray-100 py-16">
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={isVisible ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
          transition={{ duration: 0.5 }}
        >
          <h2 className="text-3xl font-bold text-center mb-8">時間割</h2>
          <motion.div 
            className="mb-8 flex flex-wrap justify-center gap-4"
            initial={{ opacity: 0, y: 20 }}
            animate={isVisible ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            <select
              value={selectedGrade}
              onChange={(e) => setSelectedGrade(e.target.value)}
              className="p-2 rounded-md border border-gray-300"
            >
              {grades.map(grade => (
                <option key={grade} value={grade}>{grade}</option>
              ))}
            </select>
            <select
              value={selectedSubject}
              onChange={(e) => setSelectedSubject(e.target.value)}
              className="p-2 rounded-md border border-gray-300"
            >
              {subjects.map(subject => (
                <option key={subject} value={subject}>{subject}</option>
              ))}
            </select>
          </motion.div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {filteredSchedule.map((day, index) => (
              <motion.div
                key={day.day}
                className="bg-white rounded-lg shadow-md overflow-hidden"
                initial={{ opacity: 0, y: 20 }}
                animate={isVisible ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <div className="bg-blue-500 text-white py-2 px-4">
                  <h3 className="text-xl font-semibold">{day.day}</h3>
                </div>
                <div className="p-4">
                  {day.classes.map((cls, clsIndex) => (
                    <div key={clsIndex} className="mb-2 last:mb-0">
                      <p className="text-sm font-medium text-gray-600">{cls.time}</p>
                      <p className="text-lg">{cls.subject} ({cls.grade})</p>
                    </div>
                  ))}
                </div>
              </motion.div>
            ))}
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default ScheduleTable;